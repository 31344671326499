import {Button} from '@mui/material';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import { DashboardDefault } from '../../../../interfaces/Dashboard';
import ModalDraggable from '../../../common/ModalDraggable';
import DashboardImportUpsertModal from './DashboardImportUpsertModal';


type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  item: DashboardDefault;
}

const DashboardImportButton = <T extends ComponentType = typeof Button>({
  component,
  componentProps,
  children,
  item
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  return (
    <>
      <Component
        {...componentProps}
        onClick={() => {
          setIsOpened(true);
        }}
      >
        {children}
      </Component>
      {isOpened ? (
        <ModalDraggable open={isOpened}>
          <DashboardImportUpsertModal
            open={isOpened}
            onClose={() => setIsOpened(false)}
            item={item}
          />
        </ModalDraggable>
      ) : null}
    </>
  );
};

export default DashboardImportButton;
