import ApartmentIcon from '@mui/icons-material/Apartment';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {Divider, IconButton, Menu, MenuItem} from '@mui/material';
import {useState} from 'react';
import {useDispatch} from 'react-redux';

import {useAppSelector} from '../../../../hooks/redux';
import {Company} from '../../../../interfaces/Company';
import reduxActions from '../../../../redux/actions';
import {CompanyItemDeleteDialog} from './CompanyitemDeleteDialog';
import {CompanyItemUpsertModal} from './CompanyitemUpsertModal';

interface Props {
  item: Company;
  onDeleted?: () => void;
  onSubmitted?: () => void;
}

export const CompanyActionsMenu = ({item, onDeleted, onSubmitted}: Props) => {
  const reduxDispatch = useDispatch();
  const currentCompanyId = useAppSelector(({app}) => app.companyId);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const menuOpened = Boolean(menuAnchorEl);
  const [isOpenedDelete, setIsOpenedDelete] = useState(false);
  const [isOpenedUpsert, setIsOpenedUpsert] = useState(false);

  return (
    <>
      <IconButton
        color="inherit"
        onClick={(event) => setMenuAnchorEl(event.currentTarget)}
      >
        <MoreHorizIcon />
      </IconButton>

      <Menu
        anchorEl={menuAnchorEl}
        open={menuOpened}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        onBackdropClick={() => setMenuAnchorEl(null)}
      >
        {currentCompanyId !== item.id ? (
          <MenuItem
            onClick={() => {
              reduxActions.app.setApp(reduxDispatch, {companyId: item.id});
              reduxActions.assets.clearAssets(reduxDispatch);
            }}
          >
            <ApartmentIcon fontSize="small" sx={{mr: 2}} />
            Set as Current
          </MenuItem>
        ) : null}

        <MenuItem
          onClick={() => {
            setIsOpenedUpsert(true);
            setMenuAnchorEl(null);
          }}
        >
          <EditIcon fontSize="small" sx={{mr: 2}} />
          Edit
        </MenuItem>

        <Divider />

        <MenuItem
          onClick={() => {
            setIsOpenedDelete(true);
            setMenuAnchorEl(null);
          }}
        >
          <DeleteOutlineOutlinedIcon fontSize="small" sx={{mr: 2}} />
          Delete
        </MenuItem>
      </Menu>

      <CompanyItemUpsertModal
        pk={item.id}
        item={item}
        prefetch
        open={isOpenedUpsert}
        onClose={() => setIsOpenedUpsert(false)}
        onSubmitted={onSubmitted}
      />

      <CompanyItemDeleteDialog
        item={item}
        open={isOpenedDelete}
        onClose={() => setIsOpenedDelete(false)}
        onDeleted={onDeleted}
      />
    </>
  );
};
