import ApartmentIcon from '@mui/icons-material/Apartment';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import {Alert, Backdrop, Box, Button, CircularProgress, Grid} from '@mui/material';
import {useEffect, useMemo, useRef, useState} from 'react';

import API, {getMessagesFromApiError} from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import {useAppSelector} from '../../../hooks/redux';
import {DashboardDefault, DashboardDefaultListResponse} from '../../../interfaces/Dashboard';
import DataGrid, {DataGridColumn, DataGridRef} from '../../common/DataGrid';
import DashboardImportButton from './dashboard/DashboardImportButton';
import { Products } from './DashboardWizardCompany';

interface Props {
  productsSelected: Products,
}

type ProductKey = 'connect' | 'proximity' | 'ams' | 'belt' | 'shaft_clearence' | 'ventilation' | 'hazard_ai' | 'alarm';

const productToName: Record<ProductKey, keyof Products> = {
  connect: "commtrac_enabled",
  proximity: "proximity_enabled",
  ams: "ams_enabled",
  belt: "belt_enabled",
  shaft_clearence: "shaft_enabled",
  ventilation: "ventilation_enabled",
  hazard_ai: "hazard_ai_enabled",
  alarm: "alarm_enabled"
} as const;

export const DashboardWizardDashboard = ({
  productsSelected
}: Props) => {
  const company = useAppSelector(({assets}) => assets.company);
  const [defaultDashboards, setDefaultDashboards] = useState<DashboardDefaultListResponse>([]);
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async () => {
    setFetchedInProgress(true);
    try {
      const resp = await API.get<DashboardDefaultListResponse>(`${apiBaseUrl}/dashboard/default`);
      setDefaultDashboards(resp.data);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    } finally {
      setFetchedInProgress(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [company]);

  /*************/
  /* data grid */
  /*************/
  const dataGridRef = useRef<DataGridRef>(null);
  const rows = useMemo(() => defaultDashboards.filter(
    (dashboard) => {
      const key = productToName[`${dashboard.product}`];
      return productsSelected[key];
    }
  ) ?? [], [defaultDashboards]);

  const columns: DataGridColumn<DashboardDefault>[] = [
    {
      field: 'name',
      headerName: 'Name',
      sortable: true,
      valueGetter: ({row}) => row.name,
    },
    {
      field: 'product',
      headerName: 'Product',
      sortable: true,
      valueGetter: ({row}) => row.product,
    },
    {
      field: 'import',
      headerName: 'Import',
      sortable: true,
      renderCell: ({row}) => {
        return (
          <Box>
            <DashboardImportButton
              component={Button}
              componentProps={{
                startIcon: (
                  <CloudDownloadIcon sx={{margin: '2px', width: '100%', fontSize: '20px'}} />
                ),
              }}
              item={row}
            />
          </Box>
        )
      }
    }
  ];

  return (
    <Box>
      <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      {fetchedErrors.map((error, index) => (
        <Alert key={index} severity="error">
          {error}{' '}
        </Alert>
      ))}
      <Grid container mb={3}>
        <Grid item xs={9}>
          <Box fontSize={24} display="flex" alignItems="center">
            <ApartmentIcon sx={{mr: 1}} />
            Dashboards
          </Box>
        </Grid>
      </Grid>
      <DataGrid
        ref={dataGridRef}
        rows={rows}
        columns={columns}
        size="small"
        sxFooter={{
          bgcolor: (theme) =>
            theme.palette.mode === 'dark' ? '#2E2E2E' : '#FFF',
        }}
      />
    </Box>
  );
};
