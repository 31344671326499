import DoneIcon from '@mui/icons-material/Done';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {Alert, Backdrop, Box, CircularProgress} from '@mui/material';
import dayjs from 'dayjs';
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../../../api/axios';
import { apiBaseUrl } from '../../../../api/urls';
import {useAppSelector} from '../../../../hooks/redux';
import {Company, CompanyListResponse} from '../../../../interfaces/Company';
import DataGrid, {DataGridColumn} from '../../../common/DataGrid';
import {Products} from '../DashboardWizardCompany';
import {CompanyActionsMenu} from './CompanyActionsMenu';

interface Props {
  companyId: number | null,
  companies: Company[] | null,
  onChange: (products: Products, id: number | null) => void;
  onSubmit: (companies: Company[]) => void;
}

export interface CompanyListRef {
  onChange: Function;
}

export const CompanyList = forwardRef<CompanyListRef, Props>(
  ({
    companyId,
    companies,
    onChange,
    onSubmit
  }, ref) => {
    const currentCompanyId = useAppSelector(({app}) => app.companyId);
    const [selectedItem, setSelectedItem] = useState<number | null>(
      companyId && companies
        ? companies
            .filter((company) => company.id === companyId)
            .map((it) => it.id)[0]
        : currentCompanyId
      );
    const selectedCompany = useMemo(
      () => companies ? companies.filter((it) => it.id === selectedItem)[0] : null,
      [selectedItem, companies]
    );
    /*******/
    /* ref */
    /*******/
    useImperativeHandle(ref, () => ({
      fetch: () => fetchData(),
      onChange: () => onChange({
        proximity_enabled: selectedCompany?.proximity_enabled ?? false,
        ams_enabled: selectedCompany?.ams_enabled ?? false,
        belt_enabled: selectedCompany?.belt_enabled ?? false,
        commtrac_enabled: selectedCompany?.commtrac_enabled ?? false,
        shaft_enabled: selectedCompany?.shaft_enabled ?? false,
        ventilation_enabled: selectedCompany?.ventilation_enabled ?? false,
        hazard_ai_enabled: selectedCompany?.hazard_ai_enabled ?? false,
        alarm_enabled: selectedCompany?.alarm_enabled ?? false,
        wifi_enabled: selectedCompany?.wifi_enabled ?? false
      }, selectedItem)
    }));


    /*************/
    /* fetch */
    /*************/
    const [fetchedInProgress, setFetchedInProgress] = useState(false);
    const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
    const fetchData = async () => {
      setFetchedInProgress(true);

      try {
        const resp = await API.get<CompanyListResponse>(`${apiBaseUrl}/company`);
        onSubmit(resp.data.items);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }

      setFetchedInProgress(false);
    };

    /*************/
    /* data grid */
    /*************/
    const rows = companies ?? [];
    const columns: DataGridColumn<Company>[] = [
      {field: 'id', headerName: 'ID'},
      {field: 'name'},
      {
        field: 'status',
        renderCell: ({row}) => {
          return row.status === 'active' ? (
            <ToggleOnIcon color="success" />
          ) : (
            <ToggleOffIcon color="error" />
          );
        },
      },
      {field: 'mine_id', headerName: 'Mine ID'},
      {
        field: 'added_date',
        valueFormatter: ({row}) => dayjs(row.added_date).format('MMM DD, YYYY'),
      },
      {
        field: 'actions',
        type: 'actions',
        sxHeader: {textAlign: 'right'},
        sxCell: {textAlign: 'right'},
        renderCell: ({row}) => {
          return (
            <CompanyActionsMenu
              item={row}
              onSubmitted={() => fetchData()}
              onDeleted={() => fetchData()}
            />
          );
        },
      },
      {
        field: 'select',
        headerName: 'Selected',
        renderCell: ({row}) => (
          <Box
            sx={{display: 'flex', justifyContent: 'center'}}
            onClick={() => handleSelectItem(row.id)}
          >
            <DoneIcon
              sx={{
                color: `${row.id === selectedItem ? 'green' : 'grey'}`,
              }}
            />
          </Box>
        ),
      },
    ];

    const handleSelectItem = (id: number) => {
      setSelectedItem(id);
      onChange({
        proximity_enabled: selectedCompany?.proximity_enabled ?? false,
        ams_enabled: selectedCompany?.ams_enabled ?? false,
        belt_enabled: selectedCompany?.belt_enabled ?? false,
        commtrac_enabled: selectedCompany?.commtrac_enabled ?? false,
        shaft_enabled: selectedCompany?.shaft_enabled ?? false,
        ventilation_enabled: selectedCompany?.ventilation_enabled ?? false,
        hazard_ai_enabled: selectedCompany?.hazard_ai_enabled ?? false,
        alarm_enabled: selectedCompany?.alarm_enabled ?? false,
        wifi_enabled: selectedCompany?.wifi_enabled ?? false
      }, selectedItem);
    }

    useEffect(() => {
      if (selectedCompany) {
        onChange({
          proximity_enabled: selectedCompany.proximity_enabled,
          ams_enabled: selectedCompany.ams_enabled,
          belt_enabled: selectedCompany.belt_enabled,
          commtrac_enabled: selectedCompany.commtrac_enabled,
          shaft_enabled: selectedCompany.shaft_enabled,
          ventilation_enabled: selectedCompany.ventilation_enabled,
          hazard_ai_enabled: selectedCompany.hazard_ai_enabled,
          alarm_enabled: selectedCompany.alarm_enabled,
          wifi_enabled: selectedCompany.wifi_enabled
        }, selectedItem);
      }
    }, [selectedCompany, companies]);

    return (
      <Box position="relative">
        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {fetchedErrors.map((error, index) => (
          <Alert key={index} severity="error" sx={{mb: 2}}>
            {error}
          </Alert>
        ))}
        <DataGrid
          rows={rows}
          columns={columns}
          size="small"
        />
      </Box>
    );
  }
);

