import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Switch,
} from '@mui/material';

import {Products, ProductSelection} from '../DashboardWizardCompany';

interface Props {
  products: Products,
  productsSelected: Products
  onSelectProduct: (selections: ProductSelection[]) => void,
  onChangeCompanyProduct: (value: Products) => void
}

export const CompanyProducts = ({
  products,
  productsSelected,
  onSelectProduct,
  onChangeCompanyProduct
}: Props) => {
  const handleChangeCompanyProduct = (key: string, value: boolean) => {
    switch (key) {
      case 'commtrac_enabled':
        onChangeCompanyProduct({
          ...products,
          hazard_ai_enabled: value ? false : products.hazard_ai_enabled,
          alarm_enabled: !value ? false : products.alarm_enabled,
          ams_enabled: !value ? false : products.ams_enabled,
          belt_enabled: !value ? false : products.belt_enabled,
          commtrac_enabled: value
        });
        onSelectProduct([
          {key: 'hazard_ai_enabled', selected: false},
          {key: 'alarm_enabled', selected: false},
          {key: 'ams_enabled', selected: false},
          {key: 'belt_enabled', selected: false},
          {key: 'commtrac_enabled', selected: false}
        ]);
        break;
      case 'hazard_ai_enabled':
        onChangeCompanyProduct({
          ...products,
          commtrac_enabled: value ? false: products.commtrac_enabled,
          hazard_ai_enabled: value
        });
        onSelectProduct([
          {key: 'commtrac_enabled', selected: false},
          {key: 'hazard_ai_enabled', selected: false}
        ]);
        break;
      case 'ams_enabled':
        onChangeCompanyProduct({
          ...products,
          ams_enabled: value
        });
        onSelectProduct([
          {key: 'ams_enabled', selected: false},
        ]);
        break;
      case 'alarm_enabled':
        onChangeCompanyProduct({
          ...products,
          alarm_enabled: value
        });
        onSelectProduct([
          {key: 'alarm_enabled', selected: false},
        ]);
        break;
      case 'proximity_enabled':
        onChangeCompanyProduct({
          ...products,
          proximity_enabled: value
        });
        onSelectProduct([
          {key: 'proximity_enabled', selected: false},
        ]);
        break;
      case 'shaft_enabled':
        onChangeCompanyProduct({
          ...products,
          shaft_enabled: value
        });
        onSelectProduct([
          {key: 'shaft_enabled', selected: false},
        ]);
        break;
      case 'belt_enabled':
        onChangeCompanyProduct({
          ...products,
          belt_enabled: value
        });
        onSelectProduct([
          {key: 'belt_enabled', selected: false},
        ]);
        break;
      case 'ventilation_enabled':
        onChangeCompanyProduct({
          ...products,
          ventilation_enabled: value
        });
        onSelectProduct([
          {key: 'ventilation_enabled', selected: false},
        ]);
        break;
    }
  }

  return (
    <Box sx={{marginTop: '50px'}}>
      <Divider>Products</Divider>

      <Box my={3}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Connect enabled"
                  disabled={
                    !!products.hazard_ai_enabled &&
                    !products.commtrac_enabled
                  }
                  control={
                    <Switch
                      checked={!!products.commtrac_enabled}
                      onChange={(event, value) => {
                        handleChangeCompanyProduct('commtrac_enabled', value)
                      }}
                    />
                  }
                />
              </Grid>
              {products["commtrac_enabled"] && (
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Include Connect"
                    control={
                      <Checkbox checked={productsSelected["commtrac_enabled"]} disabled={!products["commtrac_enabled"]} onChange={(e) => onSelectProduct([{key: 'commtrac_enabled', selected: e.target.checked}])}/>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  label="HazardAI enabled"
                  disabled={
                    !!products.commtrac_enabled &&
                    !products.hazard_ai_enabled
                  }
                  control={
                    <Switch
                      checked={!!products.hazard_ai_enabled}
                      onChange={(_, value) => {
                        handleChangeCompanyProduct('hazard_ai_enabled', value)
                      }}
                    />
                  }
                />
              </Grid>
              {products["hazard_ai_enabled"] && (
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Include Hazard AI"
                    control={
                      <Checkbox checked={productsSelected["hazard_ai_enabled"]} disabled={!products["hazard_ai_enabled"]} onChange={(e) => onSelectProduct([{key: 'hazard_ai_enabled', selected: e.target.checked}])}/>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Gas Monitoring enabled"
                  disabled={!products.commtrac_enabled}
                  control={
                    <Switch
                      checked={!!products.ams_enabled}
                      onChange={(_, value) => {
                        handleChangeCompanyProduct('ams_enabled', value)
                      }}
                    />
                  }
                />
              </Grid>
              {products["ams_enabled"] && (
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Include Gas Monitoring"
                    control={
                      <Checkbox checked={productsSelected["ams_enabled"]} disabled={!products["ams_enabled"]} onChange={(e) => onSelectProduct([{key: 'ams_enabled', selected: e.target.checked}])}/>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Alarm Module enabled"
                  disabled={!products.commtrac_enabled}
                  control={
                    <Switch
                      checked={!!products.alarm_enabled}
                      onChange={(_, value) => {
                        handleChangeCompanyProduct('alarm_enabled', value)
                      }}
                    />
                  }
                />
              </Grid>
              {products["alarm_enabled"] && (
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Include Alarm Module"
                    control={
                      <Checkbox checked={productsSelected["alarm_enabled"]} disabled={!products["alarm_enabled"]} onChange={(e) => onSelectProduct([{key: 'alarm_enabled', selected: e.target.checked}])}/>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Proximity enabled"
                  control={
                    <Switch
                      checked={!!products.proximity_enabled}
                      onChange={(event, value) => {
                        handleChangeCompanyProduct('proximity_enabled', value)
                      }}
                    />
                  }
                />
              </Grid>
              {products["proximity_enabled"] && (
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Include Proximity"
                    control={
                      <Checkbox checked={productsSelected["proximity_enabled"]} disabled={!products["proximity_enabled"]} onChange={(e) => onSelectProduct([{key: 'proximity_enabled', selected: e.target.checked}])}/>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Shaft Clearence enabled"
                  control={
                    <Switch
                      checked={!!products.shaft_enabled}
                      onChange={(_, value) => {
                        handleChangeCompanyProduct('shaft_enabled', value)
                      }}
                    />
                  }
                />
              </Grid>
              {products["shaft_enabled"] && (
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Include Shaft Clearence"
                    control={
                      <Checkbox checked={productsSelected["shaft_enabled"]}disabled={!products["shaft_enabled"]} onChange={(e) => onSelectProduct([{key: 'shaft_enabled', selected: e.target.checked}])}/>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Belt enabled"
                  disabled={!products.commtrac_enabled}
                  control={
                    <Switch
                      checked={!!products.belt_enabled}
                      onChange={(_, value) => {
                        handleChangeCompanyProduct('belt_enabled', value)
                      }}
                    />
                  }
                />
              </Grid>
              {products["belt_enabled"] && (
                <Grid item xs={6}>
                  <FormControlLabel
                    label="Include Belt"
                    control={
                      <Checkbox checked={productsSelected["belt_enabled"]} disabled={!products["belt_enabled"]} onChange={(e) => onSelectProduct([{key: 'belt_enabled', selected: e.target.checked}])}/>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={6}>
                <FormControlLabel
                  label="Ventilation enabled"
                  control={
                    <Switch
                      checked={!!products.ventilation_enabled}
                      onChange={(_, value) => {
                        handleChangeCompanyProduct('ventilation_enabled', value)
                      }}
                    />
                  }
                />
              </Grid>
              {products["ventilation_enabled"] && (
                <Grid>
                  <FormControlLabel
                    label="Include Ventilation"
                    control={
                      <Checkbox checked={productsSelected["ventilation_enabled"]} disabled={!products["ventilation_enabled"]} onChange={(e) => onSelectProduct([{key: 'ventilation_enabled', selected: e.target.checked}])}/>
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
