import {Actions} from './actions';
import {AssetsAction, AssetsState} from './types';

export const assetsInitialState: AssetsState = {
  isFetched: false,
  company: null,
  roles: [],
  users: [],
  nodes: [],
  maps: [],
  layers: [],
  color: [],
  sound: [],
  machine_types: [],
  asset_human_types: [],
  shifts: [],
  zones: [],
  sites: [],
  asset_machines: [],
  asset_humans: [],
  asset_human_groups: [],
  machine_inputs: [],
  eventBaseTypes: [],
  constants: null,
  humanAlertType: [],
  communicationNodeType: [],
  dashboardsFavourite: [],
  dashboardsPrimary: [],
  dashboardList: [],
  machineAlertType: [],
  subscriptionReportType: [],
  wifiPointType: [],
  commtrac_nodes: [],
  alarm_logs: [],
  localButtonPressAction: [],
  alarm_groups: [],
  activationReason: [],
  safeye_nodes: [],
  gas_monitorin_nodes: [],
  ams_nodes: [],
  ams_nodes_selected: [],
  ams_emodules: [],
  belt_nodes: [],
  emoduleTypeIdLabel: null,
  emoduleUnitIdLabel: null,
  belt: null,
  belts: [],
};

const assetsReducers = (state?: AssetsState, action?: AssetsAction) => {
  state = state ?? assetsInitialState;
  switch (action?.type) {
    case Actions.SetAssets:
      return {...state, ...action.data};

    default:
      return state;
  }
};

export default assetsReducers;
