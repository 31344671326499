import {Box, Button} from '@mui/material';
import { SxProps, Theme } from '@mui/material/styles';
import {
  ComponentProps,
  JSXElementConstructor,
  ReactNode,
  useState,
} from 'react';

import {DashboardWizard} from './DashboardWizard';

type ComponentType = keyof JSX.IntrinsicElements | JSXElementConstructor<any>;

interface Props<T extends ComponentType> {
  // item: Dashboard;
  component?: T;
  componentProps?: ComponentProps<T>;
  children?: ReactNode;
  sx?: SxProps<Theme>;
  // isFetching: boolean;
  // onSubmitted?: () => void;
  // onDeleted?: () => void;
}

const DashboardWizardButton = <T extends ComponentType = typeof Button>({
  // item,
  component,
  componentProps,
  children,
  sx
  // isFetching,
  // onSubmitted,
  // onDeleted,
}: Props<T>) => {
  const Component = component ?? Button;
  const [isOpened, setIsOpened] = useState(false);

  const openModal = () => setIsOpened(true);
  const closeModal = () => setIsOpened(false);

  return (
    <Box sx={sx}>
      <Component
        sx={sx}
        {...componentProps}
        onClick={openModal}
      >
        {children}
      </Component>
      <DashboardWizard
        open={isOpened}
        onClose={closeModal}
      />
    </Box>
  );
};

export default DashboardWizardButton;
