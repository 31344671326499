import ApartmentIcon from '@mui/icons-material/Apartment';
import {Backdrop, Box, CircularProgress, Grid} from '@mui/material';
import update from 'immutability-helper';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import {useEffect, useRef, useState} from 'react';
import { useDispatch } from 'react-redux';

import API, { getMessagesFromApiError } from '../../../api/axios';
import {apiBaseUrl} from '../../../api/urls';
import { useAppSelector } from '../../../hooks/redux';
import {Company, CompanyInputBody, CompanyListResponse, CompanyProduct} from '../../../interfaces/Company';
import reduxActions from '../../../redux/actions';
import {CloseSnackbarButton} from '../../common/CloseSnackbarButton';
import SnackbarMessages from '../../common/SnackbarMessages';
import {CompanyList, CompanyListRef} from './company/CompanyList';
import {CompanyProducts} from './company/CompanyProducts';

export type Products = {
  [K in CompanyProduct]: boolean;
}

export interface ProductSelection {
  key: string;
  selected: boolean;
}

interface Props {
  companyId: number | null,
  products: Products,
  productsSelected: Products,
  handleSelectCompanyProduct: (selected: Products) => void,
  onChange: (products: Products, id: number | null) => void,
  onChangeCompanyProduct: (products: Products) => void,
}

export const DashboardWizardCompany = ({
  companyId,
  products,
  productsSelected,
  handleSelectCompanyProduct,
  onChange,
  onChangeCompanyProduct,
}: Props) => {
  const currentCompanyId = useAppSelector(({app}) => app.companyId);
  const [companies, setCompanies] = useState<Company[] | []>([]);
  const reduxDispatch = useDispatch();
  const listRef = useRef<CompanyListRef | null>(null);

   /**********/
  /* submit */
  /**********/
  const [fetchInProgress, setFetchInProgress] = useState(false);
  const fetchCompanies = async () => {
    setFetchInProgress(true);

    try {
      const resp = await API.get<CompanyListResponse>(`${apiBaseUrl}/company`);
      setCompanies(resp.data.items);
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setFetchInProgress(false);
  };

  useEffect(() => {
    fetchCompanies();
  }, []);

  /**********/
  /* submit */
  /**********/
  const [submittedInProgress, setSubmittedInProgress] = useState(false);
  const submitData = async (data: Partial<CompanyInputBody>) => {
    setSubmittedInProgress(true);

    try {
      if (companyId) {
        const endpoint = `${apiBaseUrl}/company/${companyId}`;
        const resp = await API.patch(endpoint, data)
        if (companyId === currentCompanyId) {
          reduxActions.assets.setAssets(reduxDispatch, {company: resp.data});
         setCompanies(companies.map(company =>
            company.id === resp.data.id ? resp.data : company
          ));
        }
      }
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }

    setSubmittedInProgress(false);
  };

  return (
    <Box>
      <Backdrop open={submittedInProgress || fetchInProgress} sx={{position: 'absolute'}}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box py={0} display="flex" flexDirection="column">
        <Grid container mb={3}>
          <Grid item xs={9}>
            <Box fontSize={24} display="flex" alignItems="center">
              <ApartmentIcon sx={{mr: 1}} />
              Company
            </Box>
          </Grid>
        </Grid>
        <CompanyList
          ref={listRef}
          companyId={companyId}
          companies={companies}
          onChange={(products, id) => {
            onChange(products, id);
          }}
          onSubmit={(companies: Company[]) => setCompanies(companies)}
        />
        <CompanyProducts
          products={products}
          productsSelected={productsSelected}
          onSelectProduct={(selections: ProductSelection[]) => {
            const updatedProducts = selections.reduce((acc, { key, selected }) => {
              return update(acc, {
                [key]: {
                  $set: selected,
                },
              });
            }, productsSelected);

            handleSelectCompanyProduct(updatedProducts);
          }}
          onChangeCompanyProduct={(value: Products) => {
            onChangeCompanyProduct(value);
            submitData(value);
          }}
        />
      </Box>
    </Box>
  );
};
