import {Box, Button, Modal} from '@mui/material';
import {useState} from 'react';

import {DashboardWizardCompany, Products} from "./wizard/DashboardWizardCompany";
import {DashboardWizardDashboard} from "./wizard/DashboardWizardDashboard";
import DashboardWizardUsers from "./wizard/DashboardWizardUsers";

interface Props {
  open?: boolean;
  onClose?: () => void;
}

const stepList = [
  'company',
  // 'users',
  'dashboard'
]

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 900,
  border: '1px solid #9b9b9b',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  maxHeight: '100%',
  display: 'flex',      // Add this
  flexDirection: 'column' // Add this
};

export const DashboardWizard = ({open = false, onClose}: Props) => {
  const [companyId, setCompanyId] = useState<number | null>();
  const [step, setStep] = useState<string>('company');

  const [products, setProducts] = useState<Products>({
    proximity_enabled: false,
    ams_enabled: false,
    belt_enabled: false,
    commtrac_enabled: false,
    shaft_enabled: false,
    ventilation_enabled: false,
    hazard_ai_enabled: false,
    alarm_enabled: false,
    wifi_enabled: false
  });
  const [productsSelected, setProductsSelected] = useState<Products>({
    proximity_enabled: false,
    ams_enabled: false,
    belt_enabled: false,
    commtrac_enabled: false,
    shaft_enabled: false,
    ventilation_enabled: false,
    hazard_ai_enabled: false,
    alarm_enabled: false,
    wifi_enabled: false
  });

  const onChange = (products: Products, id: number | null) => {
    setProducts(products);

    if (id !== companyId) {
      setProductsSelected({
        proximity_enabled: false,
        ams_enabled: false,
        belt_enabled: false,
        commtrac_enabled: false,
        shaft_enabled: false,
        ventilation_enabled: false,
        hazard_ai_enabled: false,
        alarm_enabled: false,
        wifi_enabled: false
      });
    }
    setCompanyId(id);
  }

  const onChangeCompanyProduct = (products: Products) => {
    setProducts(products);
  }

  const handleSelectCompanyProduct = (selected: Products) => {
    setProductsSelected(selected);
  }

  const onNext = () => {
    const idx = stepList.findIndex((it) => it === step);
    if (idx < 2) {
      setStep(stepList[idx + 1]);
    }
  }

  const onPrev = () => {
    const idx = stepList.findIndex((it) => it === step);
    if (idx > 0) {
      setStep(stepList[idx - 1]);
    }
  }

  const isPrevOrNext = (value: string) => {
    return stepList.findIndex((it) => it === value);
  }

  return (
    <>
      <Modal open={open} onClose={onClose}>
        <Box sx={{ ...style, width: 800, height: 800}}>
          <Box>
            {step === 'company' && (
              <DashboardWizardCompany
                companyId={companyId || null}
                products={products}
                productsSelected={productsSelected}
                handleSelectCompanyProduct={handleSelectCompanyProduct}
                onChange={onChange}
                onChangeCompanyProduct={onChangeCompanyProduct}
              />
            )}
            {step === 'users' && (
              <DashboardWizardUsers />
            )}
            {step === 'dashboard' && (
              <DashboardWizardDashboard
                productsSelected={productsSelected}
              />
            )}
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 0.5,
              padding: 2,
              borderTop: 1,
              borderColor: 'divider',
              marginTop: 'auto'
            }}
          >
            <Button disabled={isPrevOrNext(step) === 0} onClick={() => onPrev()}>Prev</Button>
            <Button disabled={isPrevOrNext(step) === stepList.length - 1} onClick={() => onNext()}>Next</Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};
