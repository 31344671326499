import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import EditIcon from '@mui/icons-material/Edit';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import ToggleOnIcon from '@mui/icons-material/ToggleOn';
import {
  Alert,
  Backdrop,
  Box,
  CircularProgress,
  IconButton,
  Paper,
} from '@mui/material';
import {useFormik} from 'formik';
import {
  forwardRef,
  MutableRefObject,
  PropsWithChildren,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import API, {getMessagesFromApiError} from '../../../../api/axios';
import {apiBaseUrl} from '../../../../api/urls';
import {UserItemDeleteButton} from '../../../../components/users/UseritemDeleteButton';
import UserItemUpsertButton from '../../../../components/users/UserItemUpsertButton';
import {useAppSelector} from '../../../../hooks/redux';
import {User, UserListQuery, UserListResponse} from '../../../../interfaces/User';
import AccessControl from '../../../common/AccessControl';
import DataGrid, {DataGridColumn, DataGridRef} from '../../../common/DataGrid';


interface Props {}

export interface UserListRef {
  fetch?: Function;
  dataGridRef: MutableRefObject<DataGridRef | null>;
}

export const UserList = forwardRef<UserListRef, PropsWithChildren<Props>>(
  (__, ref) => {
    const me = useAppSelector(({app}) => app.me);
    const roles = useAppSelector(({assets}) => assets.roles);
    /*******/
    /* ref */
    /*******/
    useImperativeHandle(ref, () => ({
      fetch: () => fetchData(formik.values),
      dataGridRef,
    }));

    /*********/
    /* fetch */
    /*********/
    const [fetchedData, setFetchedData] = useState<UserListResponse>();
    const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
    const [fetchedInProgress, setFetchedInProgress] = useState(false);

    const fetchData = async (params: UserListQuery) => {
      setFetchedInProgress(true);
      try {
        params = {...params, order: params.order === 'role' ? 'type_id' : params.order};
        const resp = await API.get<UserListResponse>(`${apiBaseUrl}/user`, {
          params
        });
        setFetchedData(resp.data);
      } catch (error: any) {
        const messages = getMessagesFromApiError(error);
        setFetchedErrors(messages);
      }

      setFetchedInProgress(false);
    };

    /*************/
    /* data grid */
    /*************/
    const canManageUser = useCallback(
      (user: User) => me && (me.type_id === 1 || me.type_id < user.type_id),
      [me]
    );

    const dataGridRef = useRef<DataGridRef>(null);
    const rows = fetchedData?.items ?? [];
    const columns: DataGridColumn<User>[] = [
      {
        field: 'name',
        sortable: true,
      },
      {
        field: 'role',
        sortable: true,
        valueGetter: ({row}) =>
          roles.find((i) => i.id === row.type_id)?.name ?? row.type_id,
      },
      {
        field: 'status',
        sortable: true,
        renderCell: ({row}) => {
          return row.status === 'active' ? (
            <ToggleOnIcon color="success" />
          ) : (
            <ToggleOffIcon color="error" />
          );
        },
      },
      {
        field: 'actions',
        type: 'actions',
        sxHeader: {textAlign: 'right'},
        sxCell: {textAlign: 'right'},
        renderCell: ({row}) => {
          return (
            <Box display="flex" gap={1} justifyContent="end">
              <AccessControl permissions={['patch::/user/:id']}>
                <UserItemUpsertButton
                  pk={row.id}
                  item={row}
                  prefetch
                  component={IconButton}
                  componentProps={{
                    color: 'primary',
                    size: 'small',
                    disabled: !canManageUser(row),
                  }}
                  onSubmitted={() => fetchData(formik.values)}
                >
                  <EditIcon />
                </UserItemUpsertButton>
              </AccessControl>

              <AccessControl permissions={['delete::/user/:id']}>
                <UserItemDeleteButton
                  item={row}
                  prefetch
                  component={IconButton}
                  componentProps={{
                    color: 'error',
                    size: 'small',
                    disabled: row.type_id === 1 || !canManageUser(row),
                  }}
                  onDeleted={() => fetchData(formik.values)}
                >
                  <DeleteOutlineOutlinedIcon />
                </UserItemDeleteButton>
              </AccessControl>
            </Box>
          );
        },
      },
    ];

    const formik = useFormik<UserListQuery>({
      initialValues: {
        page: 0,
        limit: 25,
        order: 'id',
        dir: 'DESC',
      },
      onSubmit: () => {},
    });

    useEffect(() => {
      fetchData(formik.values);
    }, [formik.values]);

    return (
      <Paper
        sx={{
          position: 'relative',
          height: '100%',
          overflow: 'hidden',
        }}
      >
        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>

        {fetchedErrors.map((error, index) => (
          <Alert key={index} severity="error" sx={{mb: 2}}>
            {error}
          </Alert>
        ))}

        <DataGrid
          ref={dataGridRef}
          rows={rows}
          columns={columns}
          loading={fetchedInProgress}
          pagination
          paginationMode="server"
          size="small"
          sortBy={{
            field: formik.values.order,
            dir: formik.values.dir === 'DESC' ? 'desc' : 'asc',
          }}
          sortingMode="server"
          page={formik.values.page}
          pageSize={formik.values.limit}
          rowCount={fetchedData?.count}
          onPageChange={(v) => formik.setFieldValue('page', v)}
          onPageSizeChange={(v) => {
            formik.setFieldValue('limit', v);
            formik.setFieldValue('page', 0);
          }}
          onSort={(v) => {
            if (v) {
              formik.setFieldValue('order', v.field);
              formik.setFieldValue('dir', v.dir.toUpperCase());
            }
          }}
        />
      </Paper>
    );
  }
);
