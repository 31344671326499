import {Box, Modal, Typography} from '@mui/material';
import {useMemo} from 'react';

import {Company} from '../../../../interfaces/Company';
import {CompanyItemUpsert} from './CompanyItemUpsert';

interface Props {
  pk?: Company['id'];
  item?: Company;
  prefetch?: boolean;
  open: boolean;
  onSubmitted?: () => void;
  onClose?: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',
  border: '1px solid #9b9b9b',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  maxHeight: '100%',
};

export const CompanyItemUpsertModal = ({
  pk,
  item,
  prefetch,
  open,
  onSubmitted,
  onClose,
}: Props) => {
  const isCreated = useMemo(() => !!pk, [pk]);
  const title = useMemo(
    () => `${isCreated ? 'Edit' : 'Create'} Company`,
    [isCreated]
  );

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={style}>
        <Typography variant="h6" component="h2" sx={{mb: 2}}>
          {title}
        </Typography>

        <CompanyItemUpsert
          pk={pk}
          item={item}
          prefetch={prefetch}
          onCancel={onClose}
          onSubmitted={() => {
            onSubmitted?.();
            onClose?.();
          }}
        />
      </Box>
    </Modal>
  );
};
