import ApartmentIcon from '@mui/icons-material/Apartment';
import { LoadingButton } from '@mui/lab';
import { Alert, Backdrop, Box, Button, CircularProgress, Modal, TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useSnackbar } from 'notistack';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import API, { getMessagesFromApiError } from '../../../../api/axios';
import { apiBaseUrl } from '../../../../api/urls';
import { useAppSelector } from '../../../../hooks/redux';
import { Dashboard, DashboardDefault, DashboardListQuery, DashboardListResponse, DashboardUpsertInputBody } from '../../../../interfaces/Dashboard';
import reduxActions from '../../../../redux/actions';
import { CloseSnackbarButton } from '../../../common/CloseSnackbarButton';
import SnackbarMessages from '../../../common/SnackbarMessages';

interface Props {
  item: DashboardDefault
  open?: boolean;
  onClose?: () => void;
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  border: '1px solid #9b9b9b',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 3,
  overflow: 'auto',
  maxHeight: '100%',
  height: '200px',
  display: 'flex',      // Add this
  flexDirection: 'column' // Add this
};

const DashboardImportUpsertModal = ({
  item,
  open = false,
  onClose
}: Props) => {
  const me = useAppSelector(({app}) => app.me);
  /*********/
  /* fetch */
  /*********/
  const dispatch = useDispatch();
  const [fetchedErrors, setFetchedErrors] = useState<string[]>([]);
  const [fetchedInProgress, setFetchedInProgress] = useState(false);

  const fetchData = async (params: DashboardListQuery) => {
    setFetchedErrors([]);
    setFetchedInProgress(true);
    try {
      const resp = await API.get<DashboardListResponse>(
        `${apiBaseUrl}/dashboard`,
        {params}
      );
      reduxActions.assets.setAssets(dispatch, {dashboardList: resp.data.items});
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      setFetchedErrors(messages);
    }
    setFetchedInProgress(false);
  };

  /**********/
  /* submit */
  /**********/

  const {enqueueSnackbar, closeSnackbar} = useSnackbar();
  const [submittedInProgress, setSubmittedInProgress] = useState(false);

  const submitData = async (data: DashboardUpsertInputBody) => {
    const dashboarDeafultdData = {
      ...data,
      visible_to_client_viewer: false,
      visible_to_client_manager: false,
      visible_to_strata_manager: false,
      visible_to_super_user: false,
      data: item.data,
      user_id: me?.id,
      product: item.product,
      user_owner: {
        id: me?.id,
        type_id: me?.type_id
      }
    };
    setSubmittedInProgress(true);
    try {
      const endpoint = `${apiBaseUrl}/dashboard`;
      await API.post<Dashboard>(endpoint, dashboarDeafultdData);
      const message = `Dashboard successfully was imported`;
      enqueueSnackbar(message, {
        variant: 'success',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
      await fetchData({
        page: 0,
        limit: 25,
        order: 'id',
        dir: 'DESC',
      });
      onClose?.();
    } catch (error: any) {
      const messages = getMessagesFromApiError(error);
      enqueueSnackbar(<SnackbarMessages messages={messages} />, {
        variant: 'error',
        action: (key) => (
          <CloseSnackbarButton onClick={() => closeSnackbar(key)} />
        ),
      });
    }
    setSubmittedInProgress(false);
  };

  /*********/
  /* input */
  /*********/
  const inputValidationSchema = yup.object().shape({
    name: yup.string().nullable().required('Field is required'),
  });

  const getFormikValues = (): DashboardUpsertInputBody => ({
    name: null,
  });

  const formik = useFormik<DashboardUpsertInputBody>({
    initialValues: getFormikValues(),
    validationSchema: inputValidationSchema,
    onSubmit: async (values) => {
      await submitData(values);
    },
  });
  console.log(formik.values.name);
  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={{ ...style, width: 500, height: 200}}>
        <Backdrop open={fetchedInProgress} sx={{position: 'absolute'}}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {fetchedErrors.map((error, index) => (
          <Alert key={index} severity="error" sx={{my: 2}}>
            {error}
          </Alert>
        ))}
        <Box fontSize={24} display="flex" alignItems="center"sx={{mb: 1}}>
          <ApartmentIcon sx={{mr: 1}} />
          Import Dashboard
        </Box>
        <Box display="flex" flexDirection="column" gap={3}>
          <TextField
            value={formik.values.name ?? ''}
            label="Name"
            size="small"
            name="name"
            fullWidth
            error={!!formik.touched.name && !!formik.errors.name}
            helperText={formik.touched.name && formik.errors.name}
            onChange={formik.handleChange}
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 0.5,
            paddingTop: 1,
            borderTop: 1,
            borderColor: 'divider',
            marginTop: 'auto'
          }}
        >
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton
            variant="contained"
            loading={submittedInProgress}
            sx={{ml: 1}}
            onClick={() => submitData(formik.values)}
          >
            Import
          </LoadingButton>
      </Box>
      </Box>
    </Modal>
  )
};

export default DashboardImportUpsertModal;
